import addUserDetailsReducer from "./reducers/addUserDetailsReducer";
import savedRegistrationsReducer from "./reducers/savedRegistrationsReducer";
import addUserSQReducer from "./reducers/addUserSQReducer";
import saveDocumentReducer from "./reducers/saveDocumentReducer";
import addNewUserDetailsReducer from "./reducers/addNewUserDetailsReducer";
import addNewReputablePersonDetails from "./reducers/addReputableUserDetailsReducers";
import { combineReducers } from "redux";


export const reducers = combineReducers({
    addUserDetailsReducer,
    savedRegistrationsReducer, 
    addUserSQReducer,
    saveDocumentReducer,
    addNewUserDetailsReducer,
    addNewReputablePersonDetails
})
