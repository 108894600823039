import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//import FormContext from "../formContext/form.context";
// import Select from "react-select";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Camera from "./Webcam.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import selfie from "../selfie.gif";
import { useSelector, useDispatch } from "react-redux";
import { saveSelfie } from "../redux/actions/registerCustomerAction.js";
//import { ButtonGroup } from "react-bootstrap";
import Layout from "./Layout/Layout";

export const PhotoUpload = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const selfieData = useSelector(
    (state) => state.saveDocumentReducer.selfieData
  );
  const [base64, setBase64] = React.useState(() => {
    return selfieData === undefined || selfieData === null
      ? null
      : selfieData.IdBase64 === undefined
      ? null
      : selfieData.IdBase64;
  });

  const [isImageUploaded, setIsImageUploaded] = useState(
    base64 !== null && base64 !== undefined
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setIsImageUploaded(base64 !== null && base64 !== undefined);
  }, [base64]);

  const _saveSelfieDocument = () => {
    let selfieData = {};
    selfieData.IdBase64 = base64;
    // primaryIDdata.id = idNumber;
    dispatch(saveSelfie(selfieData));
    handleClose();
  };

  console.log(base64);

  return (
    <Layout>
      <div className="card-Pg2">
        <form>
          <div className="mb-3">
            <center>
              <h2 className="Auth-form-title"> Photo Upload</h2>
            </center>

            {/* <div className= "card-Pg2"> */}
            {/* <label htmlFor="exampleInputEmail1" className="form-label">
            ID Number
            
          </label> */}
            <label style={{ fontSize: "14px" }}>
              Take photo of the customer in a clear <br></br>background under
              good lighting to verify identity
            </label>
          </div>

          <div className="btn-toolbar gap-2">
            <img
              src={base64 !== null && base64 !== undefined ? base64 : selfie}
              width="100%"
              height={200}
              alt="Logo"
            />

            <br></br>
          </div>

          <div className="mb-1 col text-center">
            <Button variant="danger" onClick={handleShow} margin="auto">
              Upload Photo
            </Button>
          </div>

          <br></br>

          <div className="btn-toolbar gap-2">
            <Link to="/uploadID">
              <Button variant="danger">Back</Button>
            </Link>

            <div
              className="btn-space"
              style={{ pointerEvents: isImageUploaded ? "auto" : "none" }}
            >
              <Link to="/signature">
                <Button variant="danger" disabled={!isImageUploaded}>
                  {" "}
                  Next
                </Button>
              </Link>
            </div>
          </div>

          <div className="">
            <Modal
              show={show}
              onHide={handleClose}
              dialogClassName="custom-dialog"
            >
              <Modal.Header closeButton>
                <Modal.Title>Web Camera</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="App">
                  <Camera setBase64={setBase64} />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Link>
                  <Button variant="danger" onClick={handleClose}>
                    Close
                  </Button>
                </Link>
                <Button variant="danger" onClick={_saveSelfieDocument}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </form>
      </div>
    </Layout>
  );
};
