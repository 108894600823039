// import {
//     ADD_USER_DETAILS,
//     GET_VALIDATED_CUSTOMER_NUMBER,
//     ADD_REPUTABLE_PERSON_USER_DETAILS,
//     SET_REGISTRATION_TYPE,
//     CLEAR_USER_DETAILS
// } from "./actions/types"

import {
  ADD_USER_DETAILS,
  GET_VALIDATED_CUSTOMER_NUMBER,
  ADD_REPUTABLE_PERSON_USER_DETAILS,
  SET_REGISTRATION_TYPE,
  CLEAR_USER_DETAILS,
  GET_ID_NUMBER
} from "../actions/types";

const initialState = {
  // number: null,
  // idNumber: null,
  // firstName: null,
  // middleName: null,
  // lastName: null,
  // DOB: null,
  // gender: null,
  // citizenshipType: null,
  // country: null,
  // region: null,
  // area: null,
  // district: null,
  // occupation: null,
  // residentialAddress: null,
  // email: null,
  // altContact: null,
  isReputablePerson: false,
  registrationType: null,
  // reputablePersonFirstName: null,
  // reputablePersonMiddleName: null,
  // reputablePersonSurName: null,
  // reputablePersonDOB: null,
  // reputablePersonGender: null,
  // reputablePersonCitizenshipType: null,
  // reputablePersonCountry: null,
  // reputablePersonRegion: null,
  // reputablePersonProvince: null,
  // reputablePersonDistrict: null,
  // reputablePersonOccupation: null,
  // reputablePersonResidentialAddress: null,
  // reputablePersonEmail: null,
  // reputablePersonContact: null,

  userDetailsForm:null
};



function addUserDetailsReducer(prevState = initialState, action) {
  switch (action.type) {
    case GET_VALIDATED_CUSTOMER_NUMBER:
      return {
        ...prevState,
        number: action.number,
      };
    case ADD_USER_DETAILS:
      return {
        ...prevState,
        
        userDetailsForm: action.userDetailsForm
        // firstName: action.firstName,
        // middleName: action.middleName,
        // lastName: action.lastName,
        // DOB: action.DOB,
        // gender: action.gender,
        // citizenshipType: action.citizenshipType,
        // area: action.area,
        // occupation: action.occupation,
        // residentialAddress: action.residentialAddress,
        // email: action.email,
        // altContact: action.altContact

        //isReputablePerson: action.isReputablePerson,
        // country: action.country,
        // region: action.region,
        // district: action.district,

      };
       
    case ADD_REPUTABLE_PERSON_USER_DETAILS:
      return {
        ...prevState,
        reputablePersonFirstName: action.reputablePersonFirstName,
        reputablePersonMiddleName: action.reputablePersonMiddleName,
        reputablePersonSurName: action.reputablePersonSurName,
        reputablePersonDOB: action.reputablePersonDOB,
        reputablePersonGender: action.reputablePersonGender,
        reputablePersonCitizenshipType: action.reputablePersonCitizenshipType,
        reputablePersonCountry: action.reputablePersonCountry,
        reputablePersonRegion: action.reputablePersonRegion,
        reputablePersonProvince: action.reputablePersonProvince,
        reputablePersonDistrict: action.reputablePersonDistrict,
        reputablePersonOccupation: action.reputablePersonOccupation,
        reputablePersonResidentialAddress:
          action.reputablePersonResidentialAddress,
        reputablePersonEmail: action.reputablePersonEmail,
        reputablePersonContact: action.reputablePersonContact,
      };
    // case SET_REGISTRATION_TYPE:
    //   return {
    //     ...prevState,
    //     isReputablePerson: action.isReputablePerson,
    //     // registrationType: action.registrationType,
    //   };
    
    case GET_ID_NUMBER:
      return{
         ...prevState,
        idNumber: action.idNumber,
      };
    case CLEAR_USER_DETAILS:
      return {initialState};
    default:
      return {prevState};
  }
}

export default addUserDetailsReducer;
