import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//import FormContext from "../formContext/form.context";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useSelector, useDispatch } from "react-redux";
import { addSQ } from "../redux/actions/registerCustomerAction";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import AsyncSelect from "react-select/async";
//import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import { FormGroup,FormControlLabel, ControlLabel, HelpBlock, Checkbox, Radio, Button} from 'react-bootstrap';
import { useForm } from "react-hook-form";
import Select from "react-select";
//import { MenuItem, Select } from "@mui/material";
import { Form } from "semantic-ui-react";
import Button from "react-bootstrap/Button";
import Layout from "./Layout/Layout";


export const SecurityQuestions = () => {
  const SQ = useSelector((state) => state.addUserSQReducer.SQ);

  const q1 = SQ !== null ? SQ.question1 : null;
  const q2 = SQ !== null ? SQ.question2 : null;
  const a1 = SQ !== null ? SQ.answer1 : null;
  const a2 = SQ !== null ? SQ.answer2 : null;

  //const q2 = useSelector ((state)=>state.addUserSQReducer.SQ2);
  let [questionList1, setQuestionList1] = useState([
    {label: "Where is your favourite place to vacation?", value:1},
    {label: "What was the name of your first pet?", value: 2},
    { label: "Where did you meet your spouse?",value:3 },
    { label: "which high school did you go to?", value: 4},
    { label: "What is your mother's maiden name?", value: 5}
  ]);

  const [questionList2, setQuestionList2] = useState([
    {label: "Where is your favourite place to vacation?", value:1},
    {label: "What was the name of your first pet?", value: 2},
    { label: "Where did you meet your spouse?",value:3 },
    { label: "which high school did you go to?", value: 4},
    { label: "What is your mother's maiden name?", value: 5}
  ])
  const [question1, setQuestion1] = useState(q1 !== null ? q1 : "");
  const [selectedQuestion1, setSelectedQuestion1] = useState(
    SQ!==null 
    ?{
      value: SQ.question1,
      label: SQ.question1,
    }
    : null 
    );
  const [answer1, setAnswer1] = useState(a1 !== null ? a1 : "");
  const [question2, setQuestion2] = useState(q2 !== null ? q2 : "");
  const [selectedQuestion2, setSelectedQuestion2] = useState(
    SQ!==null 
    ?{
      value: SQ.question2,
      label: SQ.question2,
    }
    : null
  );
  const [answer2, setAnswer2] = useState(a2 !== null ? a2 : "");

  const dispatch = useDispatch();

  const submitUserSQ = () => {
    let securityQuestions = {};
    securityQuestions.question1 = question1;
    securityQuestions.question2 = question2;
    securityQuestions.answer1 = answer1;
    securityQuestions.answer2 = answer2;
    dispatch(addSQ(securityQuestions));
    //console.log(securityQuestions)
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    submitUserSQ();
    navigate("/uploadID");
    //console.log(question1);
  };


  const handleQuestion1Change = (e) => {
    setQuestion1(e.value);
    setSelectedQuestion1(e);
    if (e.value === null || e.value === undefined || e.value === 0){
      setQuestionList2(questionList1)
     
    }
    else{
      const newDataSet = questionList1.filter((data) => data.value !== e.value );
      setQuestionList2(newDataSet);
    }
  }

  const handleQuestion2Change = (e) => {
    setQuestion2(e.value);
    setSelectedQuestion2(e);
    if (e.value === null || e.value === undefined || e.value === 0){
      setQuestionList1(questionList2)
      
    }
    else{
      const newDataSet = questionList2.filter((data) => data.value !== e.value );
      setQuestionList1(newDataSet);
      console.log (newDataSet)
    }
  }

  return (

    <Layout> 
    <div className="card-Pg2">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <center>
            <h2 className="Auth-form-title"> Security Questions</h2>
          </center>

          <label htmlFor="exampleInputEmail1" className="form-label">
            Security Question 1
          </label>
          <br></br>
          <Select
            value={selectedQuestion1}
            // {...register("question1", { required: true })}
            onChange={handleQuestion1Change}

            options={questionList1}
            
          />
          <br></br>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Answer 1
          </label>

          <input
            value={answer1}
            {...register("answer1", { required: true, maxLength: 150 })}
            onChange={(e) =>
              setAnswer1((v) => (e.target.validity.valid ? e.target.value : v))
            }
            className="form-control"
            aria-describedby="emailHelp"
          />
          {errors.answer1 && (
            <p className="text-error">Please check the input feild</p>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Security Question 2
          </label>
          <br></br>
          <Select
            value={selectedQuestion2}
            onChange={handleQuestion2Change}
            options={questionList2}
          />
          <br></br>
          <label htmlFor="exampleInputEmail1" className="form-label">
            Answer 2
          </label>
          <input
            value={answer2}
            {...register("answer2", { required: true, maxLength: 150 })}
            onChange={(e) =>
              setAnswer2((v) => (e.target.validity.valid ? e.target.value : v))
            }
            className="form-control"
            aria-describedby="emailHelp"
          />
          {errors.answer2 && (
            <p className="text-error">Please check the input feild</p>
          )}
        </div>

        <br></br>

        <div className="btn-toolbar gap-1">
          <Link to="/userDetails">
            <button className="btn btn-danger">Back</button>
          </Link>

          <div className="">
            <Button
              className="btn-space"
              variant="danger"
              type="submit"
              onClick={handleSubmit(onSubmit)
             }
             
            >
              Next
            </Button>
            
          </div>
        </div>
      </Form>
    </div>
    </Layout>
  );
};