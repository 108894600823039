import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Camera from "./Webcam.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import id from "../id.gif";
import { useSelector, useDispatch } from "react-redux";
import { savePrimaryDocument } from "../redux/actions/registerCustomerAction.js";
import Layout from "./Layout/Layout";

export const UploadID = () => {
  const customerDetails = useSelector(
    (state) => state.addNewUserDetailsReducer.validateCustomerDetails
  );

  const primaryData = useSelector(
    (state) => state.saveDocumentReducer.primaryIdData
  );

  const id_Num = customerDetails !== null ? customerDetails.id_Num : null;
  // console.log(dummyData);
  // const [idNumber, setIdNumber] =  React.useState(()=>{
  //   return (primaryData===undefined||primaryData===null)?null:primaryData.id===undefined?null:primaryData.id;
  // });

  const [base64, setBase64] = React.useState(() => {
    return primaryData === undefined || primaryData === null
      ? null
      : primaryData.IdBase64 === undefined
        ? null
        : primaryData.IdBase64;
  });

  console.log(base64);

  //const [base64, setBase64] = useState(null);

  const [idNumber, setIdNumber] = React.useState(
    id_Num !== undefined ? id_Num : ""
  );
  const [showCamera, setShowCamera] = useState(false);

  const [isImageUploaded, setIsImageUploaded] = useState(
    base64 !== null && base64 !== undefined
  );

  const dispatch = useDispatch();


  useEffect(() => {
    setIsImageUploaded(base64 !== null && base64 !== undefined);
  }, [base64]);

  const _saveIdDocument = () => {
    let primaryIDdata = {};
    primaryIDdata.IdBase64 = base64;

    // let id_Number = {};
    // id_Number.id_Num = idNumber;
    //primaryIDdata.id = idNumber;
    dispatch(savePrimaryDocument(primaryIDdata));
  };

  return (
    <Layout>
      <div className="card-Pg2">
        <form>
          <div className="mb-3">
            <center>
              <h2 className="Auth-form-title"> ID Upload</h2>
            </center>

            {/* <div className= "card-Pg2"> */}
            {/* <label htmlFor="exampleInputEmail1" className="form-label">
            ID Number
            
          </label> */}
            <label style={{ fontSize: "14px" }}>
              ID should not be expired Photo of ID should be taken in clear
              lighting
            </label>
          </div>
          <div className="btn-toolbar gap-2">
            <img
              src={base64 !== null && base64 !== undefined ? base64 : id}
              width="100%"
              height={230}
              alt="Logo"
            />
          </div>
          <br></br>
          {/* <div className="btn-toolbar gap-3"> */}

          <div className="mb-1 col text-center">
            {/* <center> */}
            <Button variant="danger" onClick={() => setShowCamera(!showCamera)}>
              Upload ID
            </Button>

            {/* </center> */}
          </div>

          <br></br>

          <div className="btn-toolbar gap-2">
            <Link to="/securityQuestions">
              <Button variant="danger">Back</Button>
            </Link>

            <div className="btn-space ">
              <Link
                to="/uploadPhoto"
                onClick={() => _saveIdDocument()}
                style={{ pointerEvents: isImageUploaded ? "auto" : "none" }}
              >
                <Button variant="danger" disabled={!isImageUploaded}>
                  Next
                </Button>
              </Link>
            </div>
          </div>
        </form>
        <div>
          <Modal show={showCamera} dialogClassName="custom-dialog">
            <Modal.Header>
              <Modal.Title>Web Camera</Modal.Title>
            </Modal.Header>

            <div style={{ display: "flex", alignItems: "left", justifyContent: "space-evenly", paddingTop: "10px" }}>
              <label htmlFor="exampleInputEmail1" className="form-label" style={{ margin: "0" }}>
                ID Number
              </label>
              <input
                disabled
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                style={{ padding: '5px 10px', marginLeft: '10px', width: '250px' }}
              />
            </div>

            <Modal.Body>
              <div>
                <Camera setBase64={setBase64} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link>
                <Button
                  variant="danger"
                  onClick={() => setShowCamera(!showCamera)}
                >
                  Close
                </Button>
              </Link>
              <Button
                variant="danger"
                onClick={() => setShowCamera(!showCamera)}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};
