import {
    SAVE_REGISTRATION,
    DELETE_REGISTRATION,
} from '../actions/types';

const initialState = {
    registrations: [],
}

function savedRegistrationsReducer (prevState = initialState, action) {
    switch (action.type) {
        case SAVE_REGISTRATION: 
            return {
                ...prevState,
                registrations: [...prevState.registrations, action.registration]
            }
        case DELETE_REGISTRATION: 
            return {
                ...prevState,
                registrations: prevState.registrations.filter(registration => registration.number !== action.number)
            }
        default:
            return prevState;
    }
}

export default savedRegistrationsReducer;