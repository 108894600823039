import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "semantic-ui-react";
// import Layout from "./Layout/Layout";
import axios from "axios";

export const Changepassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [Username, setUsername] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [Newpassword, setNewPassword] = useState("");
  const [Confirmpassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const onChangeUsername = (name) => {
    setUsername(name);
  };

  const onChangeOldpassword = (oldpwd) => {
    setOldPassword(oldpwd);
  };

  const onChangeNewpassword = (newpwd) => {
    setNewPassword(newpwd);
  };

  const onChangeConfirmNewpassword = (newpwd) => {
    setConfirmPassword(newpwd);
  };

  const changepassword = async (event) => {
    await axios({
      url: "https://mpsreg-api.azurewebsites.net/api/registration/changepassword",
      method: "POST",
      data: {
        username: Username,
        oldpassword: oldpassword,
        newpassword: Newpassword,
        api_key: "YouShallPass",
      },
    })
      .then((response) => {
        console.log("res",response)
        if (response.data.response === 1001) {
          alert("Password changed successfully");
          navigate("/")

        } else {
          alert("Password change not met");
        }
      })
      .catch((e) => {
        alert("Failed to change password");
      });
  };

  const onSubmit = (data) => {
    changepassword();
  };

  const onBackToLogin = () => {
    navigate("/"); // Replace "/login" with the actual login route
  };

  return (
    // <Layout>
    <React.Fragment>
      <div className="changepassword">
        <h1>Change Password</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="form-group mt-3">
              <label>Username</label>
              <input
                className="form-control mt-1"
                placeholder="Username"
                type="text"
                {...register("Username", { required: true, maxLength: 20 })}
                onChange={(input) => {
                  onChangeUsername(input.target.value);
                }}
              />
              {errors.Username && (
                <p className="error-message">Please check the Username</p>
              )}

              <label className="mt-3">Old Password</label>
              <input
                className="form-control mt-1"
                placeholder="Password"
                type="password"
                {...register("OldPassword", {
                  required: true,
                  maxLength: 20,
                })}
                onChange={(input) => {
                  onChangeOldpassword(input.target.value);
                }}
              />
              {errors.OldPassword && (
                <p className="error-message">Please check the Password</p>
              )}

              <label className="mt-3">New Password</label>
              <input
                className="form-control mt-1"
                placeholder="New Password"
                type="password"
                {...register("NewPassword", {
                  required: true,
                  maxLength: 20,
                })}
                onChange={(input) => {
                  onChangeNewpassword(input.target.value);
                }}
              />
              {errors.NewPassword && (
                <p className="error-message">Please check New Password</p>
              )}

              <label className="mt-3">Confirm New Password</label>
              <input
                className="form-control mt-1"
                placeholder="New Password"
                type="password"
                {...register("ConfirmNewPassword", {
                  required: true,
                  maxLength: 20,
                })}
                onChange={(input) => {
                  onChangeConfirmNewpassword(input.target.value);
                }}
              />
              {errors.ConfirmNewPassword && (
                <p className="error-message">Please check New Password</p>
              )}
              <div className="btn-toolbar gap-2">
                <Button
                  className="btn btn-danger mt-4"
                  onClick={onBackToLogin}
                >
                  Cancel
                </Button>
                <Button type="submit" className="btn btn-danger mt-4">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </React.Fragment>
    // </Layout>
  );
};