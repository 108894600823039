import {
    ADD_USER_DETAILS_FORM,
    CLEAR_NEW_USER_DETAILS, 
    NEW_SET_REGISTRATION_TYPE,
    REPUTABLE_USER_DETAILS_FORM
    // SAVE_VALIDATE_CUSTOMER_FORM
  } from "../actions/types";

  const initialState = {
    newReputablePersonDetails: null,
    reputableDetails:null,
    reputableUserDetailsForm:null
  }

  const addNewReputablePersonDetails = (prevState = initialState, action) => {

    switch (action.type) {
        case ADD_USER_DETAILS_FORM: 
        //console.log('here');
            return {
                ...prevState,
                newReputablePersonDetails : action.newReputablePersonDetails
            }
        case NEW_SET_REGISTRATION_TYPE:
            return {
                ...prevState,
                reputableDetails: action.reputableDetails,
                // registrationType: action.registrationType,
            };
        case REPUTABLE_USER_DETAILS_FORM:
            return{
                ...prevState,
                reputableUserDetailsForm: action.reputableUserDetailsForm

            }
        //  case GET_VALIDATED_CUSTOMER_NUMBER:
        //         return{
        //             ...prevState,
                    
        //         }
            
        case CLEAR_NEW_USER_DETAILS:
            return initialState;
        default:
            return prevState;
    }
}

export default addNewReputablePersonDetails;