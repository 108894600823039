import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import logo from "./logo.png";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function handleLogout() {
  // Clear the session and any user data
  sessionStorage.clear();
  // Redirect the user to the login page
  window.location.href = "/";
}

// Check if the user is logged in
// const isLoggedIn = sessionStorage.getItem("isLoggedIn");

// if (isLoggedIn) {
//   window.location.href = "/";
// }

function Navigation() {
  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.83)" }}
      // overlayStyle={{backgroundColor: 'transparent'}}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="#ffffff"
          aria-label="menu"
          sx={{ mr: 2 }}
        ></IconButton>
        <img src={logo} width="2%" alt="Logo"  />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Vodafone
        </Typography>

        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;
