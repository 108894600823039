import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import Voda from "./logo.png";
import logo from "./mpaisa2.jpeg";
import { Link, useNavigate } from "react-router-dom";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

// function handleLogout() {
//   // Clear the session and any user data
//   sessionStorage.clear();
//   // Redirect the user to the login page
//   window.location.href = "/";
// }

// Check if the user is logged in
// const isLoggedIn = sessionStorage.getItem("isLoggedIn");

// if (isLoggedIn) {
//   window.location.href = "/";

// }

function Navigation() {
  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      // overlayStyle={{backgroundColor: 'transparent'}}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color=""
          aria-label="menu"
          sx={{ mr: 2 }}
        ></IconButton>

        <img src={Voda} width="2%" alt="Logo" />
        {/* <Button color="inherit">APP APK</Button> */}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Vodafone
        </Typography>

        {/* <Button color="primary">
          <a href="src/release/app-release.apk" download>
            APP APK
          </a>
        </Button> */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "8px",
          }}
        >
          <img src={logo} width="3%" alt="Mpaisa logo" />
          <Button
            variant="contained"
            color="error"
            endIcon={<DownloadIcon />}
            href="https://adminfiji-my.sharepoint.com/:f:/g/personal/anuraag_singh_vodafone_com_fj/Ei0cobLOqYBMqRddH08xvIYB3IxH3JJ2xBnmT8E9NzpScQ?e=Uyhdct"
            download
          >
            Download APP
          </Button>
          
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navigation;

{
  /* <img src={Voda} width="2%" alt="Logo" />
<Button 
variant="contained" 
// startIcon={<img src={logo} width="2%" alt="Your Image" />}
endIcon={<DownloadIcon />}>
  Download APP
</Button> */
}
