import {
    SAVE_PRIMARY_DOCUMENTS,
    // SAVE_SECONDARY_DOCUMENTS,
    // SAVE_SELFIE,
    SAVE_SIGNATURE,
    // UPDATE_SECONDARY_DOCUEMNTS
    CLEAR_DOCUMENT,
    SAVE_SEFLIE,
} from '../actions/types';

const initialState = {
    primaryIdData: null,
    signatureData: null,
    selfieData: null,
}
const saveDocumentReducer = (prevState = initialState, action) => {
    switch (action.type) {
        case SAVE_PRIMARY_DOCUMENTS: 
            return {
                ...prevState,
                primaryIdData: action.primaryIdData,
            }
        case SAVE_SIGNATURE: 
            return {
                ...prevState,
                signatureData: action.signatureData,
            }
        case SAVE_SEFLIE: 
            return {
                ...prevState,
                selfieData: action.selfieData,
            }
        case CLEAR_DOCUMENT:{
            return initialState
        }
        default:
            return prevState;
    }
}

export default saveDocumentReducer;