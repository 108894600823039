import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import { Login } from "./form/loginForm"; // login page
import { Validation } from "./form/validationForm";
import { UserDetails } from "./form/userDetailsForm";
import { SecurityQuestions } from "./form/securityQuestionsForm";
//import { Pg5 } from "./form/pg5.form";
import { PhotoUpload } from "./form/photoUploadForm";
import { SignatureForm } from "./form/signatureForm";
import { ConfirmUserDetails } from "./form/confirmUserDetails";
import { UploadID } from "./form/idUploadForm";
import { ParentGuardianEndorser } from "./form/parentGuardianEndorser";
import { Changepassword } from "./form/changepassword";
import ProtectedRoute from "./form/ProtectedRoute";
//import header from "./form/Layout/Layout";

function App() {
  return (
    <BrowserRouter basename={"/"}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/validation">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Validation />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/changepassword" element={<Changepassword />} />

        <Route path="/userDetails">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <UserDetails />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/securityQuestions">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <SecurityQuestions />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/uploadID">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <UploadID />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/uploadPhoto">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <PhotoUpload />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/signature">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <SignatureForm />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/confirmDetails">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <ConfirmUserDetails />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="/ParentGuardianEndorser">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <ParentGuardianEndorser />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* <Route path="/changepassword">
          <Route
            path=""
            element={
              <ProtectedRoute>
                <Changepassword />
              </ProtectedRoute>
            }
          />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// function App() {
//   const [pgNo, setPgNo] = useState(1);

//   return (
//     <div className="btn-toolbar- gap">
//       {/* <center className="mt-4">
//         <h3>Vodafone MPaisa Merchant </h3>

//       </center> */}
//       <div>
//         <p>Page {pgNo} / 9</p>

//         {pgNo == 1 ? (<Pg1 />) : pgNo == 2 ? (<Pg2 />) : pgNo == 3 ? (<Pg3 />) : pgNo == 4 ? (<Pg4 />) : pgNo == 5 ? (<Pg5 />) : pgNo == 6 ? (<Pg6 />) : pgNo == 7 ? (<Pg7 />) : pgNo == 8 ? (<Pg8/>) : <Pg9/>}

//         <center>
//         <br></br>
//           {pgNo > 1 && (
//             <button
//               className="btn btn-danger"
//               type="button"
//               onClick={() => {
//                 let pg = pgNo;
//                 setPgNo(pg - 1)
//               }}

//             >
//               Back
//             </button>

//           )}
//           {pgNo < 9 && (
//             <button
//               className="btn btn-danger mx-4"
//               type="button"
//               onClick={() => {
//                 let pg = pgNo;
//                 setPgNo(pg + 1);
//               }}
//             >
//               Next
//             </button>

//           )}

//         </center>
//       </div>
//     </div>
//   );
// }

// export default App;
