import React from "react";
import Navbar from "./LoginNav";



const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Navbar />
      <div className="d-flex min-vh-200">
        <div className="">
        </div>
        <div className="col-sm p-5">
          <main>{children}</main>
        </div>
      </div>
          
       
      <footer className="py-4 mt-auto border-top" style={{ minHeight: "64px" }}>
        <div className="container-xl px-5">
          <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between small">
            <div className="me-sm-2">Copyright © Vodafone Website 2023</div>
            {/* <div className="d-flex ">
              <a className="text-bold" >
                Powered By Vodafone
              </a>
            </div> */}
            <div className="me- mb-0">Powered By Vodafone</div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Layout;
