import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "./Layout/Layout";
import {
  clearDocument,
  clearSQ,
  clearUserDetails,
} from "../redux/actions/registerCustomerAction";

export const ConfirmUserDetails = () => {
  const saveUsername = useSelector(
    (state) => state.addNewUserDetailsReducer.username
  );

  // const username = saveUsername !== null ? saveUsername.username : null;

  const customerDetails = useSelector(
    (state) => state.addNewUserDetailsReducer.validateCustomerDetails
  );
  const ID_Type = useSelector((state) => state.addNewUserDetailsReducer.idType);
  const ID_Name = useSelector((state) => state.addNewUserDetailsReducer.idName);

  const dummyData = useSelector(
    (state) => state.addNewUserDetailsReducer.newUserDetailsForm
  );
  const reputableDetails = useSelector(
    (state) => state.addNewReputablePersonDetails.reputableDetails
  );
  const reputableUserDetailsForm = useSelector(
    (state) => state.addNewReputablePersonDetails.reputableUserDetailsForm
  );
  const SQ = useSelector((state) => state.addUserSQReducer.SQ);
  const primaryData = useSelector(
    (state) => state.saveDocumentReducer.primaryIdData
  );
  const selfieData = useSelector(
    (state) => state.saveDocumentReducer.selfieData
  );
  const signatureData = useSelector(
    (state) => state.saveDocumentReducer.signatureData
  );

  function handleLogout() {
    // Clear the session and any user data
    sessionStorage.clear();
    // Redirect the user to the login page
    window.location.href = "/";
  }

  const cus_Num = customerDetails !== null ? customerDetails.cus_Num : null;
  const id_Num = customerDetails !== null ? customerDetails.id_Num : null;
  const id_Name = ID_Name !== null ? ID_Name : null;

  const regType =
    reputableDetails !== null ? reputableDetails.registrationType : null;
  const Reputable =
    reputableDetails !== null ? reputableDetails.isReputablePerson : null;
  const fName = dummyData !== null ? dummyData.firstName : null;
  const mName = dummyData !== null ? dummyData.middleName : null;
  const lName = dummyData !== null ? dummyData.lastName : null;
  const dob = dummyData !== null ? dummyData.dateOfBirth : null;
  const gender = dummyData !== null ? dummyData.Gender : null;
  const area = dummyData !== null ? dummyData.Area : null;
  const citizenshipType = dummyData !== null ? dummyData.citizenship : null;
  const country = dummyData !== null ? dummyData.country : null;
  const occupation = dummyData !== null ? dummyData.OccupationIndustry : null;
  const residentialAddress =
    dummyData !== null ? dummyData.ResidentialAddress : null;
  const email = dummyData !== null ? dummyData.EmailAddress : null;
  const altContact = dummyData !== null ? dummyData.Contact : null;
  const reputableFname =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonFirstName
      : null;
  const reputableMname =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonMiddleName
      : null;
  const reputableLname =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonLastName
      : null;
  const reputableDOB =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonDateOfBirth
      : null;
  const reputableGender =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonGender
      : null;
  const reputableCitizenship =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonCitizenship
      : null;
  const reputableArea =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonArea
      : null;
  const reputableOccupation =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonOccupationIndustry
      : null;
  const reputableAddress =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonResidentialAddress
      : null;
  const reputableEmail =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonEmailAddress
      : null;
  const reputableContact =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonContact
      : null;
  const q1 = SQ !== null ? SQ.question1 : null;
  const q2 = SQ !== null ? SQ.question2 : null;
  const a1 = SQ !== null ? SQ.answer1 : null;
  const a2 = SQ !== null ? SQ.answer2 : null;
  const base64 = primaryData !== null ? primaryData.IdBase64 : "";
  const selfie = selfieData !== null ? selfieData.IdBase64 : "";
  const sign = signatureData !== null ? signatureData.IdBase64 : "";

  const [customerNumber] = useState(cus_Num !== undefined ? cus_Num : "");
  const [IDType] = useState(ID_Type !== undefined ? ID_Type : " ");
  const [idName] = useState(id_Name !== null ? id_Name : "");
  const [idNumber] = useState(id_Num !== undefined ? id_Num : " ");

  const [RegistrationType] = useState(regType !== undefined ? regType : "");
  const [firstName] = useState(fName);
  const [middleName] = useState(mName);
  const [lastName] = useState(lName);
  const [dateOfBirth] = useState(dob);
  const [Gender] = useState(gender);
  const [Area] = useState(area);
  const [citizenship] = useState(citizenshipType);
  const [Country] = useState(country);
  const [OccupationIndustry] = useState(occupation);
  const [ResidentialAddress] = useState(residentialAddress);
  const [EmailAddress] = useState(email);
  const [Contact] = useState(altContact);

  const [isReputablePerson] = useState(Reputable);
  const [reputablePersonFirstName] = useState(reputableFname);
  const [reputablePersonMiddleName] = useState(reputableMname);
  const [reputablePersonLastName] = useState(reputableLname);
  const [reputablePersonDateofBirth] = useState(reputableDOB);
  const [reputablePersonGender] = useState(reputableGender);
  const [reputablePersonCitizenship] = useState(reputableCitizenship);
  const [reputablePersonArea] = useState(reputableArea);
  const [reputablePersonOccupationIndustry] = useState(reputableOccupation);
  const [reputablePersonResidentialAddress] = useState(reputableAddress);
  const [reputablePersonEmailAddress] = useState(reputableEmail);
  const [reputablePersonContact] = useState(reputableContact);

  const [question1] = useState(q1);
  const [answer1] = useState(a1);
  const [question2] = useState(q2);
  const [answer2] = useState(a2);

  const [userID] = useState(base64);
  const [userPhoto] = useState(selfie);
  const [userSignature] = useState(sign);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clearciustomer = () => {
    dispatch(clearUserDetails());
    dispatch(clearDocument());
    dispatch(clearSQ())
  };


  // console.log("saveUsername",saveUsername);
  // console.log("reg type", RegistrationType);
  // console.log("user photo --->", userPhoto);
  // console.log("user id ---->", userID);
  // console.log("user signature ---->", userSignature);
  // console.log("DOB--->",dateOfBirth)
  // console.log("Country--->",Country)


  const saveUserInformation = async () => {
    const btnPointer = document.querySelector("#submit-btn");
    btnPointer.innerHTML = "Please wait..";
    btnPointer.setAttribute("disabled", true);

    let data = {
      username: saveUsername, //agent username

      CustomerNumber: customerNumber, //string required
      CustomerIDType: IDType, //int required -- int
      CustomerIDName: idName, //string required --
      CustomerIDNumber: idNumber, //string required

      CustomerFirstName: firstName, //string required
      CustomerMiddleName: middleName, //string optional
      CustomerSirName: lastName, //string required
      CustomerDOB: dateOfBirth, //date required
      CustomerGender: Gender, //string required "Male or Female"
      
      CustomerCitizenship: citizenship, //required number required 1 or 0 note: 1 is citizen
      CustomerResidentialArea: Area, //dropwdown town/city dropdown
      CustomerOccupation: OccupationIndustry, //required string

      CustomerResidentialAddress: ResidentialAddress, //string required vf hq

      CitizenName: Country, //required country name
      CitizenType: "58", //required country code
      Region: Area, //required string
      Province: "NA", //required string
      District: "NA", //required string


      CustomerEmail: EmailAddress, //string optional
      CustomerAlternateContact: Contact, //string optional

      CustomerSecurityQuestion1: question1, //string required
      CustomerSecurityQuestion2: question2, //string required
      CustomerSecurityAnswer1: answer1, //string require
      CustomerSecurityAnswer2: answer2, //string required

      CustomerIDUploaddata: userID, //base64 required
      CustomerSelfie: userPhoto, //base64 required
      CustomerSignatureUploadData: "data:image/jpeg;base64," + userSignature, //base64 required

      isReputablePerson: isReputablePerson, //1 or 0 note: 1 is Reputable Person Details required

      ReputablePersonFirstName: reputablePersonFirstName, //string required if reputable
      ReputablePersonMiddleName: reputablePersonMiddleName, //string optional
      ReputablePersonSirName: reputablePersonLastName, //string required if reputable
      ReputablePersonDOB: reputablePersonDateofBirth, //date required if reputable
      ReputablePersonGender: reputablePersonGender, //string required "Male or Female" if reputable
      ReputablePersonCitizenship: reputablePersonCitizenship, //required number required 1 or 0 note: 1 is citizen if reputable

      ReputablePersonCitizenshipType: "1", //required country code if reputable
      ReputablePersonCitizenshipName: "58", //required country name if reputable

      ReputablePersonResidentialArea: reputablePersonArea, //required string if reputable

      ReputablePersonRegion: "Area1", //required string if reputable
      ReputablePersonProvince: null, //required string if reputable
      ReputablePersonDistrict: null, //required string if reputable

      ReputablePersonOccupation: reputablePersonOccupationIndustry, //required string if reputable
      ReputablePersonResidentialAddress: reputablePersonResidentialAddress, //required string if reputable

      ReputablePersonEmail: reputablePersonEmailAddress,
      ReputablePersonAlternateContact: reputablePersonContact,

      RegistrationType: RegistrationType, //optional string
      api_key: "YouShallPass",
    };




    await axios
      .post(
        "https://mpsreg-api.azurewebsites.net/api/registration/submitregistration2", 
        data
      )

      .then((response) => { 
        btnPointer.innerHTML = "Submit";
        btnPointer.removeAttribute("disabled");

        console.log("response.data", response.data);
        console.log("response", response);


        if ((response.data = "this is a success" || response.data == 1001)) {
          alert("User has been succesfully Registered");
          clearciustomer();
          navigate("/validation");

        } else {
          alert("Oops! Some error occured.");
        }
      })
      .catch((error) => {
        btnPointer.innerHTML = "Submit";
        btnPointer.removeAttribute("disabled");
        alert("Please retry registration :", error);
      });
  };

  //const [base64, setBase64] = useState(idUpload);

  return (
    <Layout>
      <div className="card-Pg3">
        <label>
          <b>Register Customer</b>
        </label>
        <div>
          <label htmlFor="exampleInputEmail1" className="form-label mt-3">
            Customer Number
          </label>
          <input
            value={customerNumber}
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            disabled
          />
        </div>
        <div className="mt-3">
          <label htmlFor="exampleInputEmail1" className="form-label mt-3">
            First Name
          </label>
          <input
            value={firstName}
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            disabled
          />
        </div>
        <div className="mt-3">
          <label htmlFor="exampleInputEmail1" className="form-label mt-3">
            Last Name
          </label>
          <input
            value={lastName}
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            disabled
          />
        </div>
        <div className="mt-3">
          <label htmlFor="exampleInputEmail1" className="form-label mt-3">
            Date of Birth
          </label>
          <input
            value={dateOfBirth}
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            disabled
          />
        </div>
        <div className="mt-3">
          <label htmlFor="exampleInputEmail1" className="form-label mt-3">
            Gender
          </label>
          <input
            value={Gender}
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            disabled
          />
        </div>
        <div className="mt-3">
          <label htmlFor="exampleInputEmail1" className="form-label mt-3">
            ID Type
          </label>
          <input
            value={idName}
            type="text"
            className="form-control"
            aria-describedby="emailHelp"
            disabled
          />
        </div>

        <Container>
          <Row>
            <Col md={4}>
              {" "}
              <label htmlFor="exampleInputEmail1" className="form-label mt-3">
                Uploaded Photo
                <div>
                  <img src={userPhoto} width="70%" />
                </div>
              </label>
            </Col>

            <Col md={{ span: 4 }}>
              {" "}
              <label htmlFor="exampleInputEmail1" className="form-label mt-3">
                Uploaded ID
                <div>
                  <img src={userID} width="70%" />
                </div>
              </label>
            </Col>

            <Col md={{ span: 4 }}>
              <label htmlFor="exampleInputEmail1" className="form-label mt-3">
                Uploaded Signature
              </label>
              <div>
                <img
                  src={`data:image/png;base64,${userSignature}`}
                  width="30%"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <br />

        <div className="btn-toolbar gap-2">
          <Link to="/signature">
            <button className="btn btn-danger">Back</button>
          </Link>

          <button
            id="submit-btn"
            className="btn btn-danger"
            onClick={saveUserInformation}
          >
            Submit
          </button>
        </div>
      </div>
    </Layout>
  );
};
