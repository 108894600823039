import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Nav from "react-bootstrap/Nav";
import { useForm } from "react-hook-form";

//import FormContext from "../formContext/form.context";
//import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
//import Camera from "./Webcam.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import signing from "../signing.gif";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { saveSignature } from "../redux/actions/registerCustomerAction.js";
import Layout from "./Layout/Layout";

export const SignatureForm = () => {
  const signatureData = useSelector(
    (state) => state.saveDocumentReducer.signatureData
  );

  const [base64, setBase64] = React.useState(() => {
    return signatureData === undefined || signatureData === null
      ? null
      : signatureData.IdBase64 === undefined
      ? null
      : signatureData.IdBase64;
  });


  const [isImageUploaded, setIsImageUploaded] = useState(
    base64 !== null && base64 !== undefined
  );


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [checked, setChecked] = React.useState(
    signatureData !== null ? (signatureData.ischecked === 0 ? 0 : 1) : 0
  );

  // useEffect(() => {
  //   window.onbeforeunload = () => {
  //     localStorage.setItem('checkboxState', checked);
  //   };
  //   return () => {
  //     window.onbeforeunload = null;
  //   };
  // }, [checked]);

  const handleChange = () => {
    setChecked(checked === 0 ? 1 : 0);
  };

  const [toggleCheckBox, setToggleCheckBox] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    navigate("/confirmDetails");
    // dispatchSignature();
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsImageUploaded(base64 !== null && base64 !== undefined);
  }, [base64]);

  const dispatchSignature = () => {
    let signatureData = {};
    signatureData.IdBase64 = base64;
    dispatch(saveSignature(signatureData));
    console.log(checked);
  };

  const socketUrl = "wss://local.signotecwebsocket.de:49494";

  const [messageHistory, setMessageHistory] = useState([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onMessage: (event) => {
      let data = JSON.parse(event.data);

      //add here

      if (data.TOKEN_PARAM_FILE !== undefined) {
        setBase64(data.TOKEN_PARAM_FILE);
      }

      // console.log(data);
      // console.log(data.TOKEN_CMD);

      if (data.TOKEN_CMD === "TOKEN_CMD_SIGNATURE_CANCEL") {
        handleClickClosePad();
      } else if (data.TOKEN_CMD === "TOKEN_CMD_SIGNATURE_RETRY") {
        handleclickretry();
      } else if (data.TOKEN_CMD === "TOKEN_CMD_SIGNATURE_CONFIRM") {
        handleClickConfirmSign();
        handleclicksubmit();
        //console.log(base64);
      }
    },
  });

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  // const handleClickChangeSocketUrl = useCallback(
  //   () => setSocketUrl("wss://local.signotecwebsocket.de:49494"),
  //   []
  // );

  const handleClickSearchPad = useCallback(
    () =>
      sendMessage(
        '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SEARCH_FOR_PADS", "TOKEN_PARAM_PAD_SUBSET":"HID" }'
      ),
    []
  );

  const handleClickOpenPad = useCallback(
    () =>
      sendMessage(
        '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_OPEN_PAD", "TOKEN_PARAM_PAD_INDEX":"0" }'
      ),
    []
  );

  const handleClickClosePad = useCallback(
    () =>
      sendMessage(
        '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_CLOSE_PAD", "TOKEN_PARAM_PAD_INDEX":"0" }'
      ),
    []
  );

  const handleclickretry = useCallback(
    () =>
      sendMessage(
        '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_RETRY" }'
      ),
    []
  );

  const handleclicksubmit = useCallback(
    () =>
      sendMessage(
        '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_IMAGE' +
          '", "TOKEN_PARAM_FILE_TYPE":"' +
          "1" + // PNG
          '", "TOKEN_PARAM_PEN_WIDTH":"' +
          "5" +
          '" }'
      ),
    []
  );

  const handleClickStartSign = useCallback(
    () =>
      sendMessage(
        '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_START", "TOKEN_PARAM_FIELD_NAME":"Signature 1", "TOKEN_PARAM_CUSTOM_TEXT":"Please sign!" }'
      ),
    []
  );

  const handleClickConfirmSign = useCallback(() => {
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_CONFIRM" }'
    );
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_IMAGE' +
        '", "TOKEN_PARAM_FILE_TYPE":"' +
        "1" + // PNG
        '", "TOKEN_PARAM_PEN_WIDTH":"' +
        "5" +
        '" }'
    );
    // sendMessage(
    //   '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_CLOSE_PAD", "TOKEN_PARAM_PAD_INDEX":"0" }'
    // );
  }, []);

  const handleGetSignData = useCallback(
    () =>
      sendMessage(
        '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_IMAGE' +
          '", "TOKEN_PARAM_FILE_TYPE":"' +
          "1" + // PNG
          '", "TOKEN_PARAM_PEN_WIDTH":"' +
          "5" +
          '" }'
      ),
    []
  );

  const startSign = useCallback(() => {
    handleShow();
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SEARCH_FOR_PADS", "TOKEN_PARAM_PAD_SUBSET":"HID" }'
    );
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_OPEN_PAD", "TOKEN_PARAM_PAD_INDEX":"0" }'
    );
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_START", "TOKEN_PARAM_FIELD_NAME":"Signature 1", "TOKEN_PARAM_CUSTOM_TEXT":"Please sign!" }'
    );
  }, []);

  const saveSign = useCallback(() => {
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_CONFIRM" }'
    );
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_SIGNATURE_IMAGE' +
        '", "TOKEN_PARAM_FILE_TYPE":"' +
        "1" + // PNG
        '", "TOKEN_PARAM_PEN_WIDTH":"' +
        "5" +
        '" }'
    );
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_CLOSE_PAD", "TOKEN_PARAM_PAD_INDEX":"0" }'
    );
    //handleClose();
  }, []);

  const closePad = useCallback(() => {
    sendMessage(
      '{ "TOKEN_TYPE":"TOKEN_TYPE_REQUEST", "TOKEN_CMD":"TOKEN_CMD_CLOSE_PAD", "TOKEN_PARAM_PAD_INDEX":"0" }'
    );
    handleClose();
  }, []);

  const [isShow, invokeModal] = React.useState(false);
  const handleCl = () => setShow(false);
  const initModal = () => {
    return invokeModal(!false);
  };

  function SaveSignMethod(){
    saveSign();
    closePad();
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return (
    <Layout>
      <div className="card-Pg2">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div class="card-Pg2"> */}
          <div className="mb-3">
            <h2 className="Auth-form-title"> Terms and Condition</h2>
            {/* <label style={{ fontSize: "14px" }}>
              Read and Accept the terms and condition to authorise that all
              information is true and correct
            </label> */}
            <label style={{ fontSize: "13px" }}>
              Read and Accept the terms and condition to authorise that all
              information is true and up to date.
            </label>

            {/* <label>
            <input
              type="checkbox"
              // checked={isChecked}
              onChange={handleCheckboxChange}
              
            />
            Checkbox
          </label> */}

            <FormGroup>
              <br />
              <FormControlLabel
                control={
                  <Checkbox

                    checked={checked === 0 ? false : true}
                    onChange={handleChange}
                    // value={checked}
                    // onChange={(newValue) => setChecked(newValue)}

                    defaultChecked
                    size="small"
                  />
                }
                label={
                  <span style={{ fontSize: "12px" }}>
                    {}
                    <Nav>
                      <Nav.Item>
                        <Nav.Link fontSize="3px" onClick={handleShow2}>
                          I have read and understood the Terms and Conditions
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </span>
                }
              />

              {/* <div> */}
              <div className="btn-toolbar gap-3">
                {/* {base64 && (
              <img
                src={`data:image/png;base64,${base64}`}
                width="100%"
                alt="Logo"
              />
            )} */}

                {/* {base64 === null && <img src={signing} width="100%" alt="Logo" />} */}
                <>
                  {/* <div className="col text-center">
                <Link to="/pg6">
                  <Button variant="danger" className="mb-2">
                    Previous
                  </Button>
                </Link>
                <br></br>
                <Button variant="danger" onClick={startSign }>
                  Upload Signature
                </Button>
                <Link to="/pg8">
                  <Button variant="danger" onClick={dispatchSignature}>
                    Next
                  </Button>
                </Link>
              </div> */}

                  <Modal show={show2} onHide={handleClose2} dialogClassName="custom-dialog2">
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <b>Terms and Conditions</b>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Terms and Conditions Iam registering for Vodafone Fiji's
                      services subject to the Conditions of Use as stipulated
                      here below:
                      <br />
                      <br />
                      <b>1.</b>
                      I agree that by signing on this form electronically i am
                      deemed to have provided my consent and authority for my
                      signature to be captured in such a manner and that my
                      signature hereon constitutes my signature,acceptance and
                      agreement as if actually signed by me in writing. I
                      understand and agree that my signature hereon executed in
                      conjunction with the electronic submission of my
                      application shall be legall binding and such transaction
                      shall be considered authorised by me.
                      <br />
                      <br />
                      <b>2.</b>
                      I may only register once for the M-PAiSA service and can
                      hold one M-PAISA account only.
                      <br />
                      <br />
                      <b>3.</b>
                      I agree to pay the tariffs applicable for use of the
                      M-PAISA services and authorize Vodafone to deduct the
                      tariffs payable by me from my M-PAISA account from the
                      date of signing hereof.
                      <br />
                      <br />
                      <b>4.</b>
                      I have viewed a copy of the tariffs currently applicable
                      and agree that Vodafone reserves the right to amend the
                      tariffs from time to time.
                      <br />
                      <br />
                      <b>5.</b>
                      Iam aware that i may view he updfated list of tarriffs
                      applicable on M-PAISA via the Vodafone website on
                      <br />
                      <a href="https://www.vodafone.com.fj/">
                        www.vodafone.com.fj
                      </a>
                      <br />
                      <br />
                      <b>6.</b>
                      I have an active Vodafone Fiji and/or Inkk SIM card. I
                      hereby warrant that the MSISDN is my mobile number and
                      that am able to register anc procure M-PAISA services
                      through this number .
                      <br />
                      <br />
                      <b>7.</b>
                      I agree that the information have provided as proof of my
                      identity and age in order to register is True and correcis
                      •
                      <br />
                      <br />
                      <b>8.</b>
                      I am aware that vodatone ri may require additional
                      information to confirm my details anc activate my M-PAISA
                      account
                      <br />
                      <br />
                      <b>9.</b>
                      lam aware that | can seek independent advice on the terms
                      herein and conditions or Use •
                      <br />
                      <br />
                      <b>10.</b>
                      I understand the contents of this customel Registration
                      Form and the Conditions of Use to M-PAISA services: and
                      <br />
                      <br />
                      <b>11.</b>
                      I agree that the Customer Registration Form and concitions
                      or use construtes the entire and binding agreement between
                      me and vodatone Fiji.
                      <br />
                      <br />
                      <b>12.</b>
                      Your mobile number alwavs remains the propertv of Vodafone
                      FIT. Should vour mobile number become dormant inacrive
                      vocatone nas rignt to recycle the number and re-issue c
                      another customer. In such instances, if you have provided
                      vour number to a third party such as a bank for SMS
                      banking or other such services The onus son vouro inTorm
                      ine respective thire party to stop sending any information
                      about your account to the recycled number. Vodafone shall
                      not take an responsibility for an liability of loss
                      sustained as a result of your number being recycled.
                      <br />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={handleClose2}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </div>
            </FormGroup>
          </div>

          <div className="btn-toolbar gap-3">
            {/* add here */}
            <img
              src={
                base64 !== null && base64 !== undefined
                  ? `data:image/png;base64,${base64}`
                  : signing
              }
              width="100%"
              height={165}
              alt="Logo"
            />
          </div>

          <br />

          {/* <div>
          <button disabled={!isChecked}>Button</button>
        </div> */}

          <div className="mb-1 col text-center">
            <Button
              variant="danger"
              disabled={!checked }
              //  {...register("startSign", { required: true })}
              onClick={startSign /*handleShow*/}
            >
              Add Signature
            </Button>
          </div>

          <div className="btn-toolbar gap-2">
            <Link to="/uploadPhoto">
              <Button variant="danger" className="mb-2">
                Back
              </Button>
            </Link>

            <Link to="/confirmDetails"
              style={{ pointerEvents: isImageUploaded ? "auto" : "none" }}
             >
              
              <Button
                className="btn-space"
                variant="danger"
                disabled={!isImageUploaded}
                onClick={dispatchSignature}
              >
                Next
              </Button>
            </Link>
            {/* <div className="">
            <Button
              className="btn-space"
              variant="danger"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Nexttest
            </Button>
          </div> */}
          </div>

          <div className="col text-center">
            <br></br>
          </div>

          <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="custom-dialog"
          >
            <Modal.Header closeButton>
              <Modal.Title>Sign Pad</Modal.Title>
            </Modal.Header>
            {/* <label htmlFor="exampleInputEmail1" className="form-label">
                    Customer Number
                  </label> */}
            {/* <input
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    type="text"
                    className="form-control"
                    aria-describedby="emailHelp"
                  /> */}
            <Modal.Body>
              Please Sign on the Signature Pad Provided
              {base64 && (
                <div>
                  <img
                    src={`data:image/png;base64,${base64}`}
                    // src={base64 !== null && base64 !== undefined ? base64 : signing}
                    width="100%"
                    alt="Logo"
                  />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="danger" onClick={startSign}>
                    Start Signature
                  </Button> */}
              <Link>
                <Button variant="danger" onClick={SaveSignMethod}>
                  Save
                </Button>
              </Link>
              {/* <Button variant="danger" onClick={closePad}>
                Close
              </Button> */}
            </Modal.Footer>
          </Modal>
          {/* </> */}
          {/* </div> */}
          {/* </div> */}
        </form>
      </div>
    </Layout>
  );
};
