import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import { FormProvider } from "./formContext/form.context";
import { configureStore, applyMiddleware} from "@reduxjs/toolkit";
//import store from './app/store';
import { Provider } from "react-redux";
//import { store } from './redux/store/store';
import { reducers } from "./redux/store";
import thunk from 'redux-thunk';
// import 'semantic-ui-css/semantic.min.css'

const store = configureStore({ reducer: reducers }, 
  window._REDUX_DEVTOOLS_EXTENSION_ && window._REDUX_DEVTOOLS_EXTENSION_(),
  applyMiddleware(thunk));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    
      <App/>
    
  </Provider>
);
