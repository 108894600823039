import { useState } from "react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { addReputableDetailsForm } from "../redux/actions/registerCustomerAction";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormLabel,
  FormControlLabel,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import moment from "moment";
export const ParentGuardianEndorser = () => {
  const reputableUserDetailsForm = useSelector(
    (state) => state.addNewReputablePersonDetails.reputableUserDetailsForm
  );

  const reputableFname =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonFirstName
      : null;
  const reputableMname =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonMiddleName
      : null;
  const reputableLname =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonLastName
      : null;
  const reputableDOB =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonDateOfBirth
      : null;

  const reputableDisplayDOB =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonDisplayDateofBirth
      : null;
  const reputableGender =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonGender
      : null;
  const reputableCitizenship =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonCitizenship
      : null;
  const reputableArea =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonArea
      : null;
  const reputableOccupation =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonOccupationIndustry
      : null;
  const reputableAddress =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonResidentialAddress
      : null;
  const reputableEmail =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonEmailAddress
      : null;
  const reputableContact =
    reputableUserDetailsForm !== null
      ? reputableUserDetailsForm.reputablePersonContact
      : null;

  const [reputablePersonFirstName, setReputablePersonFirstName] = useState(
    reputableFname !== null ? reputableFname : ""
  );
  const [reputablePersonMiddleName, setReputablePersonMiddleName] = useState(
    reputableMname !== null ? reputableMname : ""
  );
  const [reputablePersonLastName, setReputablePersonLastName] = useState(
    reputableLname !== null ? reputableLname : ""
  );
  const [reputablePersonDateOfBirth, setReputablePersonDateOfBirth] = useState(
    reputableDOB !== null ? reputableDOB : ""
  );

  const [reputablePersondisplayDOB, setreputablePersondisplayDOB] = useState(
    reputableDisplayDOB !== null ? reputableDisplayDOB : new Date()
  );

  const [reputablePersonGender, setReputablePersonGender] = useState(
    reputableGender !== null ? reputableGender : ""
  );
  const [reputablePersonCitizenship, setReputablePersonCitizenship] = useState(
    reputableCitizenship !== null ? reputableCitizenship : ""
  );
  const [reputablePersonArea, setReputablePersonArea] = useState(
    reputableArea !== null ? reputableArea : ""
  );

  const [
    reputablePersonOccupationIndustry,
    setReputablePersonOccupationIndustry,
  ] = useState(reputableOccupation !== null ? reputableOccupation : "");

  const [
    reputablePersonResidentialAddress,
    setReputablePersonResidentialAddress,
  ] = useState(reputableAddress !== null ? reputableAddress : "");

  const [reputablePersonEmailAddress, setReputablePersonEmailAddress] =
    useState(reputableEmail !== null ? reputableEmail : "");

  const [reputablePersonContact, setReputablePersonContact] = useState(
    reputableContact !== null ? reputableContact : ""
  );

  const [isReputableMale, setIsReputableMale] = React.useState(() => {
    return reputableGender === null
      ? null
      : reputableGender === "male"
      ? true
      : reputableGender === "female"
      ? false
      : null;
  });
  const [isReputableFijian, setIsReputableFijian] = React.useState(() => {
    return reputableCitizenship === null
      ? null
      : reputableCitizenship === "citizen"
      ? true
      : reputableCitizenship === "non-citizen"
      ? false
      : null;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveReputablePersonDetails = () => {
    let reputablePersonDetails = {};
    reputablePersonDetails.reputablePersonFirstName = reputablePersonFirstName;
    reputablePersonDetails.reputablePersonMiddleName =
      reputablePersonMiddleName;
    reputablePersonDetails.reputablePersonLastName = reputablePersonLastName;

    reputablePersonDetails.reputablePersonDateOfBirth =
      reputablePersonDateOfBirth;
    reputablePersonDetails.reputablePersonDisplayDateofBirth =
      reputablePersondisplayDOB;

    reputablePersonDetails.reputablePersonGender = reputablePersonGender;
    reputablePersonDetails.reputablePersonCitizenship =
      reputablePersonCitizenship;
    reputablePersonDetails.reputablePersonArea = reputablePersonArea;
    reputablePersonDetails.reputablePersonOccupationIndustry =
      reputablePersonOccupationIndustry;
    reputablePersonDetails.reputablePersonResidentialAddress =
      reputablePersonResidentialAddress;
    reputablePersonDetails.reputablePersonEmailAddress =
      reputablePersonEmailAddress;
    reputablePersonDetails.reputablePersonContact = reputablePersonContact;
    dispatch(addReputableDetailsForm(reputablePersonDetails));
  };
  const onChangeReputableFirstName = (firstname) => {
    setReputablePersonFirstName(firstname);
  };

  const onChangeReputableMiddleName = (name) => {
    setReputablePersonMiddleName(name);
  };

  const onChangeReputableLastName = (lname) => {
    setReputablePersonLastName(lname);
  };

  const handleReputableDOB = (Newvalue) => {
    console.log(Newvalue);
    setReputablePersonDateOfBirth(Newvalue);
  };

  const handleReputableAreaChange = (event) => {
    setReputablePersonArea(event.target.value);
  };

  const handleReputableOccupationChange = (event) => {
    setReputablePersonOccupationIndustry(event.target.value);
  };
  const handleReputableEmailChange = (event) => {
    setReputablePersonEmailAddress(event.target.value);
  };

  const handleReputableContactChange = (event) => {
    setReputablePersonContact(event.target.value);
  };
  const handleReputableResidentialAddressChange = (event) => {
    setReputablePersonResidentialAddress(event.target.value);
  };

  const dummyData = useSelector((state) => state.addNewUserDetailsReducer);
  // console.log(dummyData);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    saveReputablePersonDetails();
    navigate("/securityQuestions");
    // estReputablePersonInd===1?"/parentGuardianEndorser":"/pg4"} onClick={() => saveUserDetails()}>
  };

  const handleDateChange = (value) => {
    try {
      if (value) {
        let dob = moment(value.toISOString()).format("YYYY/MM/DD");
        console.log(dob);
        setReputablePersonDateOfBirth(dob);
      }
      setreputablePersondisplayDOB(value);
    } catch (error) {
      console.error(error);
      // You can display an error message to the user or handle the error in some other way
    }
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="card-Pg3">
          <div>
            <label className="mb-4">
              <b>Personal Details - Parent/Guardian/Endorser</b>
            </label>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              // noValidate
              autoComplete="on"
            >
              <div className="side-by-side">
                <TextField
                  id="standard-required"
                  label="First Name"
                  type="text"
                  value={reputablePersonFirstName}
                  {...register("reputablePersonFirstName", {
                    required: true,
                    maxLength: 20,
                  })}
                  onChange={(input) => {
                    onChangeReputableFirstName(input.target.value);
                  }}
                />

                {errors.reputablePersonFirstName && (
                  <p className="text-error" style={{ fontSize: "10px" }}>
                    <br></br>Enter Your First Name
                  </p>
                )}

                <TextField
                  id="standard"
                  label="Middle Name"
                  value={reputablePersonMiddleName}
                  size="large"
                  // {...register("reputablePersonMiddleName", {
                  //   required: true,
                  //   maxLength: 7,
                  // })}
                  onChange={(input) => {
                    setReputablePersonMiddleName(input.target.value);
                  }}
                />
                {errors.reputablePersonMiddleName && (
                  <p className="text-error" style={{ fontSize: "10px" }}>
                    <br></br>Please check the Customer Number
                  </p>
                )}

                <TextField
                  id="standard"
                  label="Last Name"
                  size="large"
                  value={reputablePersonLastName}
                  {...register("reputablePersonLastName", {
                    required: true,
                    maxLength: 20,
                  })}
                  onChange={(input) => {
                    setReputablePersonLastName(input.target.value);
                  }}
                  className="form-control"
                  aria-describedby="emailHelp"
                />

                {errors.reputablePersonLastName && (
                  <p className="text-error" style={{ fontSize: "10px" }}>
                    <br></br>Enter Your Last Name
                  </p>
                )}
              </div>

              <div>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    value={reputablePersondisplayDOB}
                    inputFormat="DD/MM/YYYY"
                    onChange={(value) => {
                      setreputablePersondisplayDOB(value.toString());

                      let dob = moment(value.toISOString()).format(
                       "YYYY/MM/DD"
                      );

                      console.log(dob);

                      setReputablePersonDateOfBirth(dob);

                      // setReputablePersonDateOfBirth(dob);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={reputablePersondisplayDOB}
                    inputFormat="DD/MM/YYYY"
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} required />}
                  />
                </LocalizationProvider>

                <div className="row-lg-2">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Gender
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={reputablePersonGender}
                      {...register("reputablePersonGender", { required: true })}
                      // onchange={handleGenderChange}
                      onChange={(e) => {
                        setReputablePersonGender(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={"female"}
                        control={<Radio />}
                        label="Female"
                        onChange={() => {
                          setIsReputableMale(false);
                        }}
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                        onChange={() => {
                          setIsReputableMale(true);
                        }}
                        {...register("reputablePersonGender", {
                          required: true,
                        })}
                      />

                      {errors.reputablePersonGender && (
                        <p className="text-error" style={{ fontSize: "10px" }}>
                          <br></br>Select Your Gender
                        </p>
                      )}
                    </RadioGroup>
                  </FormControl>

                  <div className="row-lg-2">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Citizenship
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={reputablePersonCitizenship}
                        {...register("reputablePersonCitizenship", {
                          required: true,
                        })}
                        onChange={(e) => {
                          setReputablePersonCitizenship(
                            parseInt(e.target.value)
                          );
                        }}
                      >
                        <FormControlLabel
                          value= "1"
                          control={<Radio />}
                          label="Citizen"
                          onChange={() => {
                            setIsReputableFijian(true);
                          }}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Non-Citizen"
                          {...register("reputablePersonCitizenship", {
                            required: true,
                          })}
                          onChange={() => {
                            setIsReputableFijian(false);
                          }}
                        />

                        {errors.reputablePersonCitizenship && (
                          <p
                            className="text-error"
                            style={{ fontSize: "10px" }}
                          >
                            <br></br>Select Your Citizenship
                          </p>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            </Box>

            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              // noValidate
              autoComplete="off"
            >
              <div>
                <label className=" mt-4 mb-4">
                  <b>Address Information </b>
                </label>
              </div>

              <div>
                <FormControl sx={{ m: 1, minWidth: 180 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Town/City
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={reputablePersonArea}
                    label="Tow/City"
                    {...register("reputablePersonArea", { required: true })}
                    onChange={handleReputableAreaChange}

                    // onChange={(e) => {
                    //   setIDType(e.target.value);
                    // }}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="Ba">Ba</MenuItem>
                    <MenuItem value="Bua">Bua</MenuItem>
                    <MenuItem value="Cakaudrove">Cakaudrove</MenuItem>
                    <MenuItem value="Kadavu">Kadavu</MenuItem>
                    <MenuItem value="Korovou">Korovou</MenuItem>
                    <MenuItem value="Labasa">Labasa</MenuItem>
                    <MenuItem value="Lami">Lami</MenuItem>
                    <MenuItem value="Lau">Lau</MenuItem>
                    <MenuItem value="Lautoka">Lautoka</MenuItem>
                    <MenuItem value="Levuka">Levuka</MenuItem>
                    <MenuItem value="Lomaiviti">Lomaiviti</MenuItem>
                    <MenuItem value="Macuata">Macuata</MenuItem>
                    <MenuItem value="Makoi">Makoi</MenuItem>
                    <MenuItem value="Mamanuca">Mamanuca</MenuItem>
                    <MenuItem value="Nabouwalu">Nabouwalu</MenuItem>
                    <MenuItem value="Nabua">Nabua</MenuItem>
                    <MenuItem value="Nadi">Nadi</MenuItem>
                    <MenuItem value="Naitasiri">Naitasiri</MenuItem>
                    <MenuItem value="Nakasi">Nakasi</MenuItem>
                    <MenuItem value="Namosi">Namosi</MenuItem>
                    <MenuItem value="Nasinu">Nasinu</MenuItem>
                    <MenuItem value="Nausori">Nausori</MenuItem>
                    <MenuItem value="Navua">Navua</MenuItem>
                    <MenuItem value="Pacific Harbour">Pacific Harbour</MenuItem>
                    <MenuItem value="Ra">Ra</MenuItem>
                    <MenuItem value="Raiwaqa">Raiwaqa</MenuItem>
                    <MenuItem value="RakiRaki">RakiRaki</MenuItem>
                    <MenuItem value="Rewa">Rewa</MenuItem>
                    <MenuItem value="Rotuma">Rotuma</MenuItem>
                    <MenuItem value="Samabula">Samabula</MenuItem>
                    <MenuItem value="Savusavu">Savusavu</MenuItem>
                    <MenuItem value="Seaqaqa">Seaqaqa</MenuItem>
                    <MenuItem value="Serua">Serua</MenuItem>
                    <MenuItem value="Sigatoka">Sigatoka</MenuItem>
                    <MenuItem value="Suva">Suva</MenuItem>
                    <MenuItem value="Tailevu">Tailevu</MenuItem>
                    <MenuItem value="Taveuni">Taveuni</MenuItem>
                    <MenuItem value="Tavua">Tavua</MenuItem>
                    <MenuItem value="Valelevu">Valelevu</MenuItem>
                    <MenuItem value="Vatukoula">Vatukoula</MenuItem>
                    <MenuItem value="Yasawa">Yasawa</MenuItem>
                  </Select>
                  {errors.reputablePersonArea && (
                    <p className="text-error" style={{ fontSize: "10px" }}>
                      <br></br>Select Your Area
                    </p>
                  )}
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 250 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {" "}
                    Occupation Industry
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={reputablePersonOccupationIndustry}
                    label="Occupation Industry"
                    {...register("reputablePersonOccupationIndustry", {
                      required: true,
                    })}
                    onChange={handleReputableOccupationChange}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="Accounting">Accounting</MenuItem>
                    <MenuItem value="Agriculture,Food/Beverage and Natural Resources">
                      Agriculture,Food/Beverage and Natural Resources
                    </MenuItem>
                    <MenuItem value="Architecture and Construction">
                      Architecture and Construction
                    </MenuItem>
                    <MenuItem value="Arts, Audio/Video Technology and Communication">
                      Arts, Audio/Video Technology and Communication
                    </MenuItem>
                    <MenuItem value="Banking and Financial Services">
                      Banking and Financial Services
                    </MenuItem>
                    <MenuItem value="Education and Training">
                      Education and Training
                    </MenuItem>
                    <MenuItem value="Government and Public Administration">
                      Government and Public Administration
                    </MenuItem>
                    <MenuItem value="Hospital and Healthcare">
                      Hospital and Healthcare
                    </MenuItem>
                    <MenuItem value="Manufacturing and Retail">
                      Manufacturing and Retail
                    </MenuItem>
                    <MenuItem value="Professional Sports">
                      Professional Sports
                    </MenuItem>
                    <MenuItem value="Science, Technology, Engineering and Mathematics">
                      Science, Technology, Engineering and Mathematics
                    </MenuItem>
                    <MenuItem value="Telecommunications">
                      Telecommunications
                    </MenuItem>
                    <MenuItem value="Tourism and Hospitality">
                      Tourism and Hospitality
                    </MenuItem>
                    {/* <MenuItem value="Others">Others</MenuItem> */}
                  </Select>
                  {errors.reputablePersonOccupationIndustry && (
                    <p className="text-error" style={{ fontSize: "10px" }}>
                      <br></br>Select Your Occupation Industry
                    </p>
                  )}
                </FormControl>

                <FormControl sx={{ m: 0, minWidth: 300 }}>
                  <TextField
                    id="standard"
                    label="Residential Address"
                    defaultValue=""
                    value={reputablePersonResidentialAddress}
                    size="large"
                    {...register("reputablePersonResidentialAddress", {
                      required: true,
                    })}
                    onChange={handleReputableResidentialAddressChange}

                    // className="form-control"
                    // aria-describedby="emailHelp"
                  />
                  {errors.reputablePersonResidentialAddress && (
                    <p
                      className="text-error"
                      style={{ fontSize: "10px", m: 1 }}
                    >
                      Enter Your ResidentialAddress
                    </p>
                  )}
                </FormControl>
              </div>
            </Box>

            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              // Validate
              autoComplete="off"
            >
              <div>
                <label className="mt-4 mb-4">
                  <b>Contact Information</b>
                </label>
              </div>
              <div>
                <FormControl>
                  <TextField
                    id="standard"
                    label="Email Address"
                    defaultValue=""
                    value={reputablePersonEmailAddress}
                    size="large"
                    {...register("reputablePersonEmailAddress", {
                      required: true,
                      maxLength: 20,
                    })}
                    onChange={handleReputableEmailChange}
                    className="form-control"
                    aria-describedby="emailHelp"
                  />

                  {errors.reputablePersonEmailAddress && (
                    <p className="text-error" style={{ fontSize: "10px" }}>
                      <br></br>Enter Your Email Address
                    </p>
                  )}
                </FormControl>

                <FormControl>
                  <TextField
                    id="standard"
                    label="Alternative Contact"
                    defaultValue=""
                    value={reputablePersonContact}
                    size="large"
                    {...register("reputablePersonContact", {
                      required: true,
                      maxLength: 7,
                    })}
                    onChange={handleReputableContactChange}
                    className="form-control"
                    aria-describedby="emailHelp"
                  />

                  {errors.reputablePersonContact && (
                    <p className="text-error" style={{ fontSize: "10px" }}>
                      <br></br>Enter Your Contact
                    </p>
                  )}
                </FormControl>

                <br />
                <div>
                  <br />

                  <div className="btn-toolbar gap-3">
                    <Link to="/userDetails">
                      <button className="btn btn-danger">Back</button>
                    </Link>

                    <Link to="/securityQuestions">
                      <Button
                        className=" btn btn-danger"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Next
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};
