// import {
//   ADD_USER_DETAILS,
//  ADD_SQ,
//   SAVE_PRIMARY_DOCUMENTS,
//   SAVE_SIGNATURE,
//   GET_VALIDATED_CUSTOMER_NUMBER,
//   ADD_REPUTABLE_PERSON_USER_DETAILS,
//   SET_REGISTRATION_TYPE,
//   SAVE_SEFLIE,
// } from "./types";

import {
  GET_VALIDATED_CUSTOMER_NUMBER,
  NEW_SET_REGISTRATION_TYPE,
  ADD_USER_DETAILS,
  ADD_REPUTABLE_PERSON_USER_DETAILS,
  GET_ID_NUMBER,
  ADD_SQ,
  SAVE_PRIMARY_DOCUMENTS,
  SAVE_SIGNATURE,
  SAVE_SEFLIE,
  CLEAR_DOCUMENT,
  CLEAR_NEW_USER_DETAILS,  
  CLEAR_SQ,
  ADD_USER_DETAILS_FORM,
  SAVE_VALIDATE_CUSTOMER_FORM,
  ADD_REPUTABLE_USER_DETAILS,
  VALIDATE_CUSTOMER_NUMBER,
  ID_TYPE,
  REPUTABLE_USER_DETAILS_FORM,
 USERNAME,

} from "./types";

export const validateCustomer = (validateCustomerDetails) => {
  return{
    type: SAVE_VALIDATE_CUSTOMER_FORM,
    validateCustomerDetails : validateCustomerDetails,
  };
}
export const addIdType = (idType, IDName)=>{
  return{
    type: ID_TYPE,
    idType : idType,
    idName: IDName
  };

}
export const addIdNumber = (idNumber) => {
  return {
    type: GET_ID_NUMBER,
    idNumber: idNumber,
  };
};

export const getValidatedCustomerNumber = (customerNumber) => {
  return {
    type: GET_VALIDATED_CUSTOMER_NUMBER,
    number: customerNumber,
  };
};
export const newSetRegistrationType = (reputableDetails) => {
  return {
    type: NEW_SET_REGISTRATION_TYPE,
    reputableDetails:reputableDetails
    // isReputablePerson: isReputablePerson,
    // registrationType: registrationType,
  };
};

export const addNewReputablePersonDetails = (
  newReputablePersonDetails
  
)=>({
  type:ADD_REPUTABLE_USER_DETAILS,
  newReputablePersonDetails: newReputablePersonDetails
})

export const addReputableDetailsForm = (reputableUserDetailsForm)=>{
  return{
  type:REPUTABLE_USER_DETAILS_FORM,
  reputableUserDetailsForm:reputableUserDetailsForm
};
};

export const addNewUserDetails = (
  newUserDetailsForm
)=> ({
  type:ADD_USER_DETAILS_FORM,
  newUserDetailsForm:newUserDetailsForm
});

export const addCustomerValidateDetails = (
  validateCustomerDetails 
) => ({
type: VALIDATE_CUSTOMER_NUMBER,
validateCustomerDetails : validateCustomerDetails
});

export const addUserDetails = (
  
  userDetailsForm
) => ({
  type: ADD_USER_DETAILS,
  userDetailsForm:userDetailsForm
});

// for username
export const addUsername = (username) => {
  return{
    type: USERNAME,
     username : username ,
  };
}


export const addUReputablePersonDetails = (
  reputablePersonFirstName,
  reputablePersonMiddleName,
  reputablePersonSurName,
  reputablePersonDOB,
  reputablePersonGender,
  reputablePersonCitizenshipType,
  reputablePersonCountry,
  reputablePersonRegion,
  reputablePersonProvince,
  reputablePersonDistrict,
  reputablePersonOccupation,
  reputablePersonResidentialAddress,
  reputablePersonEmail,
  reputablePersonContact
) => ({
  type: ADD_REPUTABLE_PERSON_USER_DETAILS,
  reputablePersonFirstName: reputablePersonFirstName,
  reputablePersonMiddleName: reputablePersonMiddleName,
  reputablePersonSurName: reputablePersonSurName,
  reputablePersonDOB: reputablePersonDOB,
  reputablePersonGender: reputablePersonGender,
  reputablePersonCitizenshipType: reputablePersonCitizenshipType,
  reputablePersonCountry: reputablePersonCountry,
  reputablePersonRegion: reputablePersonRegion,
  reputablePersonProvince: reputablePersonProvince,
  reputablePersonDistrict: reputablePersonDistrict,
  reputablePersonOccupation: reputablePersonOccupation,
  reputablePersonResidentialAddress: reputablePersonResidentialAddress,
  reputablePersonEmail: reputablePersonEmail,
  reputablePersonContact: reputablePersonContact,
});

export const addSQ = (SQ) => ({
  type: ADD_SQ,
  SQ: SQ,
  //SQ2: SQ2,
});

export const savePrimaryDocument = (primaryIdData) => ({
  type: SAVE_PRIMARY_DOCUMENTS,
  primaryIdData: primaryIdData,
});

export const saveSignature = (signatureData) => ({
  type: SAVE_SIGNATURE,
  signatureData: signatureData,
});

export const saveSelfie = (selfieData) => ({
  type: SAVE_SEFLIE,
  selfieData: selfieData,
});

export const clearDocument = ()=>(
  {
      type: CLEAR_DOCUMENT
  }
)
export const clearUserDetails = ()=> (
  {
      type: CLEAR_NEW_USER_DETAILS,
  }
);
export const clearSQ = ()=>(
  {
      type: CLEAR_SQ
  }
)