export const ADD_USER_DETAILS = 'ADD_USER_DETAILS';
export const ADD_REPUTABLE_PERSON_USER_DETAILS = 'ADD_REPUTABLE_PERSON_USER_DETAILS';
export const ADD_SQ = 'ADD_SQ';
export const SAVE_PRIMARY_DOCUMENTS = 'SAVE_PRIMARY_DOCUMENTS';
export const SAVE_SIGNATURE = 'SAVE_SIGNATURE';
export const GET_VALIDATED_CUSTOMER_NUMBER = 'GET_VALIDATED_CUSTOMER_NUMBER';
export const SAVE_REGISTRATION = 'SAVE_REGISTRATION';
export const DELETE_REGISTRATION = 'DELETE_REGISTRATION';
export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';
export const CLEAR_SQ = 'CLEAR_SQ';
export const SET_REGISTRATION_TYPE ='SET_REGISTRATION_TYPE';
export const NEW_SET_REGISTRATION_TYPE ='NEW_SET_REGISTRATION_TYPE';
export const SAVE_SEFLIE = 'SAVE_SEFLIE';
export const GET_ID_NUMBER = 'GET_ID_NUMBER';
export const ADD_USER_DETAILS_FORM = 'USER_DETAILS_FORM';
export const CLEAR_NEW_USER_DETAILS = 'CLEAR_NEW_USER_DETAILS';
export const ADD_REPUTABLE_USER_DETAILS = 'REPUTABLE_USER_DETAILS';
export const SAVE_VALIDATE_CUSTOMER_FORM = 'SAVE_VALIDATE_CUSTOMER_FORM';
export const VALIDATE_CUSTOMER_NUMBER = 'VALIDATE_CUSTOMER_NUMBER';
export const ID_TYPE = 'ID_TYPE';
export const REPUTABLE_USER_DETAILS_FORM = 'REPUTABLE_USER_DETAILS_FORM';
export const USERNAME = 'USERNAME';