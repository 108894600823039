import {ADD_SQ, CLEAR_SQ} from '../actions/types';

const initialState = {
    SQ: null,
    //SQ2: null
}

const addUserSQReducer = (prevState = initialState, action) => {
    switch (action.type) {
        case ADD_SQ: 
            return {
                ...prevState,
                SQ: action.SQ
            }
        case CLEAR_SQ:
            return initialState;
        default:
            return prevState;
    }
}

export default addUserSQReducer;