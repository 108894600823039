import React, { useRef } from "react";
import Webcam from "react-webcam";
import Button from "react-bootstrap/Button";
import { compressImageFile } from "image-compressor";

 
const videoConstraints = {
  width: 450,
  facingMode: "environment",
};
 
const Camera = ({setBase64}) => {
  const webcamRef = useRef(null);
  const [url, setUrl] = React.useState(null);

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    setUrl(imageSrc);
    setBase64(imageSrc);
    //  console.log(imageSrc.substring(23,39923));
    
  }, [webcamRef]);

  const onUserMedia = (e) => {
    console.log(e);
  };

  return (
    <>
      {url===null && (
        <Webcam
          ref={webcamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          onUserMedia={onUserMedia}
        />)}
      {url && (
        <div className="image-container">
          <img src={url} alt="Screenshot"    style={{ alignSelf: 'center' }}/>
          
        </div>
      )}

      <br></br>
      <div className="btn-toolbar gap-3 form-row text-center" >

      
      <Button variant="danger" onClick={capturePhoto}>Take Photo</Button>
      <Button variant="danger" onClick={() => setUrl(null)}>Re-Upload</Button>
      </div>
    </>
  );
};

export default Camera;
